<template>
  <div
    class="vue-codemirror"
    :class="{ merge }">
    <div
      ref="mergeview"
      v-if="merge" />
    <textarea
      ref="textarea"
      v-else />
  </div>
</template>

<script>
// lib
import _CodeMirror from 'codemirror'
// Codemirror base CSS
import 'codemirror/lib/codemirror.css'
// require active-line.js
import 'codemirror/addon/selection/active-line.js'
// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
// autoCloseTags
import 'codemirror/addon/edit/closetag.js'
// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.css'
import 'codemirror/addon/search/match-highlighter.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
// search
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/search/jump-to-line.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'

const CodeMirror = window.CodeMirror || _CodeMirror

// pollfill
if (typeof Object.assign !== 'function') {
  Object.defineProperty(Object, 'assign', {
    value(target, varArgs) {
      if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object')
      }
      const to = Object(target)
      for (let index = 1; index < arguments.length; index++) {
        const nextSource = arguments[index]
        if (nextSource != null) {
          for (const nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true
  })
}

export default {
  name: 'VueCodemirror',
  props: {
    code: String,
    value: String,
    marker: Function,
    unseenLines: Array,
    merge: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    events: {
      type: Array,
      default: () => []
    },
    globalOptions: {
      type: Object,
      default: () => {}
    },
    globalEvents: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      content: '',
      codemirror: null,
      cminstance: null
    }
  },
  watch: {
    options: {
      deep: true,
      handler(options, oldOptions) {
        const cmOptions = Object.assign({}, this.globalOptions, this.options)
        require(`codemirror/theme/${cmOptions.theme}.css`)
        for (const key in options) {
          this.cminstance.setOption(key, options[key])
        }
      }
    },
    code(newVal, oldVal) {
      this.handerCodeChange(newVal, oldVal)
    },
    value(newVal, oldVal) {
      this.handerCodeChange(newVal, oldVal)
    },
    width(newVal, oldVal) {
      this.cminstance.setSize(newVal, '100%')
    }
  },
  mounted() {
    this.initialize()
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    initialize() {
      const cmOptions = Object.assign({}, this.globalOptions, this.options)
      // Load theme dynamically
      if (cmOptions.theme !== 'default') {
        require(`codemirror/theme/${cmOptions.theme}.css`)
      }
      if (this.merge) {
        this.codemirror = CodeMirror.MergeView(this.$refs.mergeview, cmOptions)
        this.cminstance = this.codemirror.edit
      } else {
        this.codemirror = CodeMirror.fromTextArea(this.$refs.textarea, cmOptions)
        this.cminstance = this.codemirror
        this.cminstance.setSize('100%', '100%')
        this.cminstance.setValue(this.code || this.value || this.content)
      }
      this.cminstance.on('change', cm => {
        this.content = cm.getValue()
        if (this.$emit) {
          this.$emit('input', this.content)
        }
      })
      const events = [
        'scroll',
        'changes',
        'beforeChange',
        'cursorActivity',
        'keyHandled',
        'inputRead',
        'electricInput',
        'beforeSelectionChange',
        'viewportChange',
        'swapDoc',
        'gutterClick',
        'gutterContextMenu',
        'focus',
        'blur',
        'refresh',
        'optionChange',
        'scrollCursorIntoView',
        'update'
      ]
        .concat(this.events)
        .concat(this.globalEvents)
      const onEdEvents = {}
      for (let i = 0; i < events.length; i++) {
        if (typeof events[i] === 'string' && onEdEvents[events[i]] === undefined) {
          // eslint-disable-next-line
          ;(event => {
            onEdEvents[event] = null
            this.cminstance.on(event, (a, b, c) => {
              this.$emit(event, a, b, c)
            })
          })(events[i])
        }
      }
      this.$emit('ready', this.codemirror)
      this.unseenLineMarkers()
      // prevents funky dynamic rendering
      this.$nextTick(this.refresh)
    },
    refresh() {
      this.cminstance.refresh()
    },
    destroy() {
      // garbage cleanup
      const element = this.cminstance.doc.cm.getWrapperElement()
      element && element.remove && element.remove()
    },
    handerCodeChange(newVal, oldVal) {
      const cmValue = this.cminstance.getValue()
      if (newVal !== cmValue) {
        const scrollInfo = this.cminstance.getScrollInfo()
        this.cminstance.setValue(newVal)
        this.content = newVal
        this.cminstance.scrollTo(scrollInfo.left, scrollInfo.top)
      }
      this.unseenLineMarkers()
    },
    unseenLineMarkers() {
      if (this.unseenLines !== undefined && this.marker !== undefined) {
        this.unseenLines.forEach(line => {
          const info = this.cminstance.lineInfo(line)
          this.cminstance.setGutterMarker(line, 'breakpoints', info.gutterMarkers ? null : this.marker())
        })
      }
    }
  }
}
</script>

<style lang="scss">
.vue-codemirror {
  width: 100%;
  height: 100%;
}
// @import '../../../../node_modules/codemirror/lib/codemirror.css'
</style>
